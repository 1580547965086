.questionContainer {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: center;
  min-height: 100%;

  svg {
    width: 100%;
    max-width: 30px;
    cursor: pointer;
    max-height: 20px;
  }

  .questionMainSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    // max-width: 50px;
    gap: 5px;
    padding: 0px 10px 10px 10px;

    span {
      font-weight: normal;
    }

    .questionHeaderSection {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      // border: 1px solid var(--lightGrey);
      // border-radius: 5px;
      //padding: 10px 50px;
      padding-bottom: 5px;
      gap: 15px;
      justify-content: space-between;

      span {
        font-weight: bold;
        // font-size: var(--secondaryFont);
      }

      .headingBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          color: var(--primaryColor);
          font-weight: 700;
          font-size: 22px;
        }

        p {
          font-weight: 700;
          font-size: var(--primaryFont);
        }
      }

      .contentBlock {
        display: flex;
        flex-direction: column;

        p {
          font-weight: bold;

          a {
            font-weight: normal;
            color: var(--secondaryColor);
            //   font-size: var(--secondaryFont);
            text-decoration: underline;

            &:hover {
              color: var(--primaryColor);
              text-decoration: underline;
            }
          }
        }

        .flexParagraph {
          display: flex;
          gap: 5px;
          align-items: center;
          padding: 10px 0;

          & > div {
            // max-width: 280px;
            font-weight: normal;
          }
        }
      }
    }

    .questionAnswerSection {
      background-color: #fff;
      display: flex;
      border: 1px solid var(--lightGrey);
      border-radius: 5px;
      justify-content: space-between;
      align-items: center;
      // flex-direction: column;
      flex-wrap: wrap;
      padding-bottom: 20px;

      .questionNavigator {
        padding: 10px 30px 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        // min-height: 55px;
        gap: 10px;
        align-items: center;
        background: var(--backgroundColor);
        position: relative;
        .assignBtnContainer {
          position: absolute;
          right: 0;
          top: 10px;
          width: 100%;
        }
        .questionPagination {
          position: absolute;
          left: 30px;
        }
        //   padding: 10px 50px;
        .questionNavigationHead {
          display: flex;
          gap: 10px;
          align-items: center;
          // justify-content: space-between;
        }

        p {
          font-size: var(--primaryFont);
          // color: var(--primaryColor);
          font-weight: 700;
          padding: 6px 0;
        }

        .backIconParent svg {
          padding: 5px;
        }

        .disabled {
          cursor: no-drop;

          svg {
            &:hover {
              fill: #000 !important;
              background-color: transparent;
            }
          }
        }

        svg {
          cursor: pointer;
          width: 45px;
          max-width: 35px !important;
          padding: 8px;
          border-radius: 50%;
          fill: #fff !important;
          background-color: var(--secondaryColor);

          &:hover {
            opacity: 0.8;
          }
        }

        button {
          position: absolute;
          right: 30px;

          svg {
            padding: 0;
            opacity: 1;
            border-radius: 0;
          }
        }
      }

      .questionInnerCommon {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
      }
    }

    .assignedQuestionSection {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 20px 30px 20px;
      gap: 10px;
      justify-content: space-between;

      textarea {
        border: 2px solid var(--tableBorderColor);
      }

      .statusContainer {
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }

      p {
        span {
          font-weight: 700;
        }
      }

      .optionBtnBar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        .radioOptions {
          display: flex;
          gap: 10px;

          // .optionContent {
          p {
            font-size: 14px;
          }

          // }
        }

        .guidanceBtn {
          display: flex;
          flex-direction: column;
          // align-items: center;
          height: fit-content;
          border: 1px solid var(--secondaryColor);
          border-radius: 5px;
          width: 95%;
          padding: 5px 10px;
          margin: 0 auto;

          .guiadnacetext {
            font-size: 12px;
            color: gray;
            font-style: italic;
            font-weight: normal;
          }

          .acoordion {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          p {
            color: var(--secondaryColor);
            font-weight: 600;
            cursor: pointer;
            font-size: 14px;
          }

          svg {
            fill: var(--secondaryColor);
            width: 15px;
            height: 15px;
          }
        }

        button {
          min-width: 80px;

          &:hover,
          &.active {
            background: var(--secondaryColor) !important;
            color: var(--whiteColor) !important;
          }
        }
      }
    }

    .remediationSection {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      // border: 1px solid var(--lightGrey);
      // border-radius: 5px;
      justify-content: space-between;
      width: 100%;

      button {
        // &:hover {
        //   background: var(--hoverBtnColor) !important;
        //   color: var(--whiteColor) !important;
        // }
      }

      p {
        padding: 20px 30px;
      }

      .taskHeadingBlock {
        background: var(--backgroundColor);
        padding: 10px 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          padding: 0;
          font-weight: 700;
        }

        .assignee {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;

          button {
            margin-left: 10px;
          }
        }
      }

      .remediationTaskTable {
        max-height: 300px;
        overflow-y: auto;
        padding: 20px;
      }
    }

    .uploadDocumentContainer {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      justify-content: space-between;
      gap: 20px;
      width: 100%;

      button {
        // &:hover {
        //   background: var(--hoverBtnColor) !important;
        //   color: var(--whiteColor) !important;
        // }
      }

      p {
        padding: 0px 30px 20px;
      }

      .taskHeadingBlock {
        background: var(--backgroundColor);
        padding: 10px 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          padding: 0;
          font-weight: 700;
        }

        label {
          margin-right: 0px;

          position: relative;
        }
      }

      .uploadedFile {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        padding: 0px 30px;

        .deleteIcon {
          &:hover {
            fill: red;
          }
        }

        .pdfFile {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: var(--secondaryFont);
          width: 100%;
          // min-width: 500px;
          overflow-wrap: break-word;
          max-width: 500px;

        }

        .pdfDate {
          color: var(--textColor);
          font-size: var(--secondaryFont);
          }

        .moreIcon {
          justify-content: center;
          display: flex;
          width: 100%;
          max-width: 95px;
        }

        &:last-child {
          margin-bottom: 15px;
        }
      }
    }

    .submitInputContainer {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      // margin-bottom: 20px;

      .submitInputHeader {
        background: var(--backgroundColor);
        padding: 10px 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
      }

      .commentInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        position: relative;

        textarea {
          min-height: 45px;
          // border: none;
          border: 2px solid var(--tableBorderColor);
          // border-bottom: 2px solid var(--tableBorderColor);
        }

        button {
          position: absolute;
          right: 54px;
          bottom: 10px;
        }
      }
    }

    .commentContainer {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      gap: 20px;
      width: 100%;
      padding: 0px 30px 10px;
    }

    .questionInnerCol6 {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border: 1px solid var(--lightGrey);
      border-radius: 5px;
      justify-content: space-between;
      width: 100%;

      button {
        // &:hover {
        //   background: var(--hoverBtnColor) !important;
        //   color: var(--whiteColor) !important;
        // }
      }

      span {
        font-weight: normal;
        //  font-size: var(--secondaryFont);
      }

      .contentBlock {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;

        p {
          font-weight: bold;

          a {
            font-weight: normal;
            color: var(--secondaryColor);
            //  font-size: var(--secondaryFont);
            text-decoration: underline;

            &:hover {
              color: var(--primaryColor);
              text-decoration: underline;
            }
          }
        }
      }

      .taskHeadingBlock {
        background: var(--backgroundColor);
        padding: 10px 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: 700;

          &:first-child {
            font-size: var(--primaryFont);
          }

          &:last-child {
            font-size: var(--secondaryFont);
            color: var(--textColor);
          }
        }
      }
    }

    .questionCommentSection {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: #fff;
      padding: 0px 30px;
      align-items: center;
      border: 1px solid var(--lightGrey);
      border-radius: 5px;

      input {
        padding: 10px 5px;
        border: none;
        width: 100%;
      }

      .socialLinks {
        display: flex;
        gap: 10px;

        svg {
          max-width: 20px;
        }
      }
    }
  }
}

.answerListContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Guidance {
  font-size: 13px;
  color: grey;
  font-style: italic;
}

.iconContainerQUestion {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;

  button {
    width: 100%;
  }

  svg {
    max-width: 26px;
    max-height: 26px;

    &:hover {
      fill: red;
    }
  }
}

.ropaButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  min-width: 240px;

  button {
    width: 100%;
  }

  svg {
    max-width: 26px;
    max-height: 26px;

    &:hover {
      fill: red;
    }
  }
}

.articleInfo {
  // margin-left: 1.5rem;
}

.isException {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin: 10px 0;

  & > div {
    width: 17.5%;
  }
}

.dueDateMessage{
  margin-left: 2rem;
  color: #904439;
  margin-right: 2rem;
  margin-top: 0.5rem;
  padding: 7px;
  background-color: #f7d7da;
  width: 100%;
}

.userLoginInner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  input {
    padding: 4px 5px;
    border-radius: 5px;
    width: 100%;
  }

  label {
    font-size: var(--primaryFont);
  }
}

@media screen and (max-width: 990px) {
  .questionContainer {
    // max-width: 550px;
    padding: 0px 15px;
  }
}
@media screen and (max-width: 880px) {
  .questionContainer {
    .questionMainSection {
      .questionAnswerSection {
        .questionNavigator {
          padding: 10px;
          justify-content: space-between;
          .questionPagination {
            position: static;
          }
          .assignBtnContainer {
            position: static;
            width: fit-content;
            display: flex;
            align-items: center;
            button {
              position: static;
            }
          }
        }
      }
    }
  }
  .questionTableContainer {
    width: 100%;
    overflow: auto;
  }
}

