/* @import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&display=swap); */
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Mulish&family=Quicksand:wght@300;400;500;600;700&display=swap"); */
/* @import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap); */
/* @import url("https://fonts.cdnfonts.com/css/black-crow"); */

:root {
  /* --secondaryColor: #0097ce; */
  --secondaryColor: #3277ac;
  --primaryColor: #270089;
  --mainBgColor: #f5f5f5;
  --lightGrey: #d9d9d9;
  --textColor: #80838d;
  --listHoverColor: #f5ecf5;
  --backgroundColor: #f1f2f4;
  --tableBorderColor: #dfe2eb;
  --purpleColor: #712cf9;
  --whiteColor: #fff;
  --blackColor: #000;
  --greyColor: #575757;
  --redColor: #ff0000;
  --disableColor: #cacaca;
  --hoverBtnColor: #08b6f5;
  --primaryFont: 16px;
  --secondaryFont: 14px;
  --logoFont: 25px;
  --greenColor: #50c878;
  /* --fontFamily: "Quicksand";
  --siteFontFamily: "BLACK CROW", sans-serif; */
  --fontFamily: "Mulish";
  --siteFontFamily: "Mulish";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.svg_image_icon {
  max-width: 17px;
  /* margin-right: 8px; */
  cursor: pointer;
  display: inline-flex;
  align-self: center;
  max-height: 17px;
  object-fit: contain;
}

.manageAssessmentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  /* height: fit-content; */
  /* padding: 10px 20px 0px 20px; */
}

.svg_image_icon svg {
  max-height: 16px;

  disable {}
}

svg {
  width: 100%;
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  padding: 0;
  margin: 0 !important;
}

input {
  outline: none;
}

.backToLogin {
  margin-top: 20px;
}

.backToLogin a {
  text-decoration: none;
  color: var(--textColor);
}

.backToLogin a:hover {
  text-decoration: none;
  /* color:var(--blackColor) */
}

.paddb-5 {
  padding-bottom: 5px;
  width: 100%;
}

input:read-only {
  background-color: var(--mainBgColor);
}

.Select-menu-outer * {
  max-height: 95px;
}

.Select-menu-outer {
  max-height: 30px;
}

.Select-menu {
  max-height: 30px;
}

.contentHeader {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.contentHeader svg {
  fill: var(--secondaryColor);
}

.rdrDefinedRangesWrapper {
  width: 200px !important;
}

.rdrCalendarWrapper {
  width: 500px !important;
}
.form-check-input{
border-color: #000 !important;
}