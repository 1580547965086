.mainContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mainContainerInner {
  display: flex;
  flex-direction: column;
  width: 98%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem; 
  width: 100%;
}

.contentHeader{
  width: 8rem;
}
// .contentContainer{
//   display: flex;
//   width: 100%;
//   flex-direction: row;
// }
.contentContainer{
  margin-top: 2rem;
  
}
.tableContainer{
  width: 98%;
}

.departmentLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

