.duplicatePopupContainer {
  width: 100%;
  display: flex;
  justify-content: safe center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: 98%;
  overflow-y: auto;
  // position: fixed;
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;    border: none !important;
  outline: none !important;

  .duplicatePopupContainerInner {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    overflow: hidden;
    // height: 100%;
    z-index: 2;
    border-radius: 15px;
    .duplicateAssessmentHeader {
      width: 100%;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      padding: 15px 30px;
      justify-content: space-between;
      align-items: center;
    }
    .duplicateAssessmentBody {
      padding: 15px 30px;
      display: flex;
      background-color: var(--whiteColor);
      display: flex;
      flex-direction: column;
      gap: 30px;
      // max-height: 590px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      max-height: 70vh;
      overflow: auto;
      .duplicateAssessmentContent {
        display: flex;
        gap: 5px;
        flex-direction: column;
        p {
          label {
            font-weight: 700;
          }
        }
      }
      span {
        font-weight: normal;
      }
      a {
        cursor: pointer;
      }
      .contentBlock {
        display: flex;
        flex-direction: column;
        p {
          font-weight: bold;

          a {
            font-weight: normal;
            color: var(--secondaryColor);
            font-size: var(--secondaryFont);
            text-decoration: underline;
            &:hover {
              color: var(--primaryColor);
              text-decoration: underline;
            }
          }
        }
      }

      .buttonPanel {
        width: 100%;
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        align-items: center;

        #cancelbtn {
          color: pink !important;
        }

        a {
          color: var(--primaryColor);
          text-decoration: underline;
          font-weight: 600;
        }
      }
      input {
        width: 100%;
        border-radius: 4px;
        padding: 5px 10px;
        border: 1px solid var(--textColor);
      }
      p {
        overflow: auto;
        max-height: 150px;
      }
      .mainBlock {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;

        label{
          margin-bottom: 5px;
        }
      }
    }
  }
}
.form-group {
  width: 50%;
}
@media screen and (max-width: 990px) {
  .duplicatePopupContainer {
    .duplicatePopupContainerInner {
      max-width: 75%;
      margin-left: 65px;
    }
  }
}
@media screen and (max-width: 550px) {
  .duplicatePopupContainer {
    .duplicatePopupContainerInner {
      max-width: 75%;
      top: 18%;
      .addChapterRow {
        gap: 20px;
        .userLogin {
          gap: 10px;
          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }
        .inviteUserBtn {
          // flex-direction: column;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}
