.addDepartmentContainer {
  width: 100%;
  display: flex;
  justify-content: safe center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  z-index: 2;
  border-radius: 15px;
  height: 100%;
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  border: none !important;
  outline: none !important;

  .addDepartmentContainerInner {
    width: 100%;
    border-radius: 15px;
    z-index: 2;

    .invitePopupRow1 {
      width: 100%;
      background-color: var(--secondaryColor);
      color: var(--whiteColor);
      display: flex;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding: 15px 30px;
      justify-content: space-between;
      align-items: center;
    }

    .invitePopupRow2 {
      background-color: #fff;
      width: 100%;
      padding: 15px 30px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      display: flex;
      flex-direction: column;
      gap: 65px;
      // overflow: auto;
      overflow: auto;
      max-height: 65vh;

      p {
        color: var(--textColor);
        font-weight: 600;
      }

      .userLogin {
        display: flex;
        gap: 25px 10px;
        flex-direction: column;

        label {
          font-size: var(--primaryFont);
        }

        .userLoginInner {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            padding: 8px 10px;
            border-radius: 5px;
            width: 100%;
            // margin-right: 2px;
          }
        }
      }

      .inviteUserBtn {
        display: flex;
        gap: 15px;
        justify-content: right;
        margin-top: 25px;
      }
    }
  }
}


@media screen and (max-width: 990px) {
  .addDepartmentContainer {
    .addDepartmentContainerInner {
      max-width: 75%;
      margin-left: 65px;
    }
  }
}

@media screen and (max-width: 550px) {
  .addDepartmentContainer {
    .addDepartmentContainerInner {
      max-width: 75%;
      top: 18%;

      .invitePopupRow2 {
        gap: 20px;

        .userLogin {
          gap: 10px;

          .userLoginInner {
            flex-direction: column;
            gap: 10px;
            padding: 0;
          }
        }

        .inviteUserBtn {
          justify-content: center;
          // margin-top: 20px;
          flex-wrap: wrap;
          button{
            width: 100%;
            max-width: 100% !important;
          }
        }
      }
    }
  }
}