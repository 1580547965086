.manageAssessmentContainer {
  min-height: 100%;
  .adminAssessmentLeft {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    h3 {
      //  color: var(--textColor);
      font-size: var(--primaryFont);
      font-weight: 800;
    }
  }

  .authData {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-end;
    // flex: 1 1 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--mainBgColor);
    padding: 9px 8px 9px 9px;
    .loggedInUser {
      display: flex;
      flex-direction: column;
      justify-content: right;
      flex: 1 1 70%;

      .loggedInTime {
        text-align: "center";
        font-size: "14px";
      }
      .loggedInTimeLabel {
        font-weight: normal;
      }

      .loggedInUserContainer {
        display: flex;
        gap: 10px;
        border-radius: 10px;
        // align-items: center;
        width: 100%;
        // max-width: 180px;
        // max-width: 250px;
        position: relative;
        // padding: 10px 10px 20px;
        justify-content: flex-end;
        cursor: pointer;
        &:hover {
          .logoutUi {
            display: flex;
          }
        }
        .logoutUi {
          position: absolute;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          top: 95%;
          width: 100%;
          max-width: 180px;
          margin: 0 auto;
          transition: all 0.5s ease;
          display: none;
          background-color: var(--whiteColor);
          padding: 5px 5px;
          border-radius: 5px;
          -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
          a {
            color: var(--primaryColor);
            text-decoration: none;
          }
        }

        .userInitial {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 100%;
          max-width: 40px;
          height: 40px;
          // flex: 1 1 50%;
          background-color: var(--secondaryColor);

          p {
            font-size: var(--primaryFont);
            color: var(--whiteColor);
            margin: 0;
            text-transform: uppercase;
            font-weight: bolder;
            padding: 5px;
          }
        }
        .userInfo {
          // flex: 1 1 50%;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            &:nth-of-type(1) {
              font-weight: 600;
              font-size: 14px;

              color: var(--primaryColor);
            }
            &:nth-of-type(2) {
              font-size: 13px;
              color: var(--textColor);
            }
          }
        }
      }
    }
  }
  .headingAdminUser {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    flex-wrap: wrap;

    // border: 1px solid var(--tableBorderColor);
    // padding: 10px 20px 0px 20px;
    border-radius: 5px;

    .topBtns{
      display: flex;
      gap: 10px;
    }

    h2 {
      font-size: calc(var(--primaryFont) + 2px);
      font-weight: 800;
      color: var(--primaryColor);
    }
    .headingAdminTop {
      display: flex;
      gap: 10px;
      flex-direction: column;
      flex-basis: 100%;
      h5 {
        font-size: var(--secondaryFont);
      }
    }
  }
  .manageAssessmentHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 20px;
    p {
      color: var(--textColor);
      display: flex;
      gap: 20px;
      margin: 0;
    }
  }
  .manageAssessmentBody {
    display: flex;
    flex-direction: column;
    //gap: 20px;
    // padding: 20px;
    //border: 1px solid rgba(0, 0, 0, 0.1);
    // background-color: var(--whiteColor);

    .assessmentBtnDiv {
      display: flex;
      justify-content: flex-end;
    }
    .adminAssessmentContentTop {
      h2 {
        font-size: 22px;
        font-weight: normal;
      }
      p {
        color: var(--textColor);
        display: flex;
        gap: 4px;
        font-size: var(--secondaryFont);
      }
    }
  }
  .manageAssessmentBodyBottom {
    margin-top: 15px;
    width: 100%;
    .adminAssessmentContentBottom {
      display: flex;
      gap: 15px;
      flex-direction: column;

      .adminAssessmentList {
        display: flex;
        gap: 20px;
        overflow-y: auto;

        ul {
          display: flex;
          gap: 15px;
          //flex-direction: column;
          padding-left: 0;
          flex-wrap: wrap;

          li {
            display: flex;
            // flex-direction: column;
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            min-width: 300px;
            position: relative;
            align-items: flex-start;
            justify-content: space-between;
            flex: 1;
            max-width: 300px;

            .branchListCard {
              display: flex;
              flex-direction: column;
              flex: 1;
              padding-right: 5px;
            }
            svg {
              // position: absolute;
              // right: 10px;
              // top: 10px;
              //fill: var(--greenColor);
              fill: var(--secondaryColor);
              max-width: 14px;
              min-width: 14px;
              height: auto;
              flex: 0;
              padding-top: 4px;
              cursor: pointer;
            }
            a svg {
              position: static;
              max-width: 14px;
              //  margin-right: 5px;
              fill: var(--primaryColor);
            }
            span {
              color: var(--textColor);
              // b {
              color: var(--blackColor);
              // }
            }
            a {
              color: var(--blackColor);
              text-decoration: none;
            }
          }
        }
      }
      .adminAssessmentRight {
        flex-basis: 48%;
        img {
          width: 100%;
        }
      }
    }
  }
}
.assesmentLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.EditIcon{
  width: 20px !important;
  height: 20px !important;
}