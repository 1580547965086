// .manageAssessmentContainer {
//   min-height: 100%;
//   overflow: auto;

//   .manageAssessmentHeader {
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     padding: 5px 20px;

//     p {
//       color: var(--textColor);
//       display: flex;
//       gap: 20px;
//       margin: 0;
//     }
//   }

//   .authData {
//     // display: flex;
//     // flex-direction: column;
//     // align-items: flex-end;
//     // flex: 1 1 50%;
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//     background-color: var(--mainBgColor);
//     padding: 9px 8px 9px 9px;
//     .loggedInUser {
//       display: flex;
//       flex-direction: column;
//       justify-content: right;
//       flex: 1 1 70%;

//       .loggedInTime {
//         text-align: "center";
//         font-size: "14px";
//       }
//       .loggedInTimeLabel {
//         font-weight: normal;
//       }

//       .loggedInUserContainer {
//         display: flex;
//         gap: 10px;
//         border-radius: 10px;
//         // align-items: center;
//         width: 100%;
//         // max-width: 180px;
//         // max-width: 250px;
//         position: relative;
//         // padding: 10px 10px 20px;
//         justify-content: flex-end;
//         cursor: pointer;
//         &:hover {
//           .logoutUi {
//             display: flex;
//           }
//         }
//         .logoutUi {
//           position: absolute;
//           align-items: center;
//           justify-content: center;
//           flex-direction: column;
//           top: 95%;
//           width: 100%;
//           max-width: 180px;
//           margin: 0 auto;
//           transition: all 0.5s ease;
//           display: none;
//           background-color: var(--whiteColor);
//           padding: 5px 5px;
//           border-radius: 5px;
//           -webkit-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
//           -moz-box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
//           box-shadow: 0px 3px 4px 0px rgba(202, 202, 202, 1);
//           a {
//             color: var(--primaryColor);
//             text-decoration: none;
//           }
//         }

//         .userInitial {
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           border-radius: 50%;
//           width: 100%;
//           max-width: 40px;
//           height: 40px;
//           // flex: 1 1 50%;
//           background-color: var(--secondaryColor);

//           p {
//             font-size: var(--primaryFont);
//             color: var(--whiteColor);
//             margin: 0;
//             text-transform: uppercase;
//             font-weight: bolder;
//             padding: 5px;
//           }
//         }
//         .userInfo {
//           // flex: 1 1 50%;
//           display: flex;
//           flex-direction: column;

//           p {
//             margin: 0;
//             &:nth-of-type(1) {
//               font-weight: 600;
//               font-size: 14px;
//               color: var(--primaryColor);
//             }
//             &:nth-of-type(2) {
//               font-size: 13px;
//               color: var(--textColor);
//             }
//           }
//         }
//       }
//     }
//   }

//   .breadCrumb {
//     display: flex;
//     width: 100%;

//     .fileInput {
//       width: 100%;
//       max-width: fit-content;
//     }
//   }

//   .manageAssessmentBody {
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
//     // padding: 20px;
//     height: 100%;
//     // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//     // border: 1px solid rgba(0, 0, 0, 0.1);
//     background-color: var(--whiteColor);
//     // padding: 1rem;
//     // padding: 10px 20px 0px 20px;

//     .assessmentRow {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       border-radius: 5px;
//       gap: 15px;
//       width: 100%;
//       flex-wrap: wrap;

//       .filtrSearchDiv {
//         display: flex;
//         align-items: center;
//         gap: 15px;
//         width: 100%;
//         max-width: 48%;
//         justify-content: space-between;
//       }
//     }

//     .assessmentMain {
//       width: 100%;
//       // height: 100%;
//       // max-height: 424px;
//       max-height: 60%;
      

//       margin-top: 10px;
//       overflow: auto;
//       margin: 0 auto;
//       // height: 100%;
//       table {
//         tr {
//           td {
//             min-width: 200px;
//             overflow-wrap: break-word;
//             &:last-child {
//               min-width: fit-content;
//             }
//           }
//         }
//       }

//       .assessmentLoader {
//         display: flex;
//         width: 100%;
//         height: 100%;
//         align-items: center;
//         justify-content: center;
//       }
//     }

//     .assessmentSpanDiv {
//       display: flex;
//       justify-content: flex-start;
//       gap: 10px;

//       .assessmentSpan {
//         display: flex;
//         gap: 8px;
//         flex-wrap: wrap;

//         h4 {
//           text-transform: uppercase;
//           font-size: 12px;
//           flex-basis: 100%;
//         }

//         span {
//           background-color: var(--whiteColor);
//           border-radius: 12px;
//           padding: 2px 10px;
//           border: 1px solid var(--textColor);
//           font-size: 12px;

//           a {
//             margin-right: 8px;
//           }
//         }
//       }
//     }
//   }
// }
// .assesmentLoader {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 60vh;
// }
// .assessmentheader {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   position: relative;
//   h4 {
//     padding: 0;
//   }
// }
// .datePicker {
//   position: absolute;
//   background-color: #fff;
//   z-index: 100;
//   top: 0%;
//   right: 0;
//   border: 1px solid var(--primaryColor);
//   border-radius: 8px;
//   overflow: hidden;
//   margin-top: 24px;

//   h6 {
//     padding-bottom: 14px;
//   }
// }
// .buttonContainer {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   gap: 15px;
// }
// .daterangeContainer {
//   display: flex;
//   align-items: center;
//   gap: 10px;
// }
// @media screen and (max-width: 770px) {
//   .assessmentRow {
//     .searchContainer {
//       width: 100%;
//       > div {
//         max-width: 100% !important;
//       }
//     }
//     .filtrSearchDiv {
//       display: flex;
//       align-items: center;
//       gap: 15px;
//       width: 100%;
//       max-width: 100% !important;
//       justify-content: space-between;
//     }
//   }

//   .assessmentheader {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     .filtrSearchDiv {
//       width: 100%;
//     }
//     .daterangeContainer {
//       justify-content: space-between;
//       flex-wrap: wrap;
//     }
//   }
// }
// .errorMessage{
//   color: red;
// }


.assessmentComparisonContainer {
  padding: 1rem;
  // background-color: #f8f9fa;
  border-radius: 8px;
  width: 100%;
  
  .assessmentheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    color: var(--primaryColor);
    h4 {
      padding: 0;
    }
    
    p {
      color: #666;
      font-size: 0.875rem;
    }
  }

  // .comparisonSelections{
  //   display: flex;
  //   width: 100%;
  // }
  
  // .assessmentBody {
  //   background-color: #fff;
  //   border-radius: 6px;
  //   padding: 1.5rem;
  //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  // }
  
  .selectionRow {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    width: 100%;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .assessmentTypeRow {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    width: 50%;
    margin-top: 2rem;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .filterDropdown {
    flex: 1;
    min-width: 250px;
  }
  
  .actionButtons {
    display: flex;
    justify-content: flex-end;
    margin: 5rem 0 2rem;
    
    button {
      min-width: 180px;
    }
  }
  
  .errorMessage {
    color: var(--redColor);
    margin: 1rem 0;
    padding: 0.75rem;
    background-color: rgba(255, 0, 0, 0.05);
    border-radius: 4px;
    border-left: 3px solid var(--redColor);
  }
  
  .comparisonResults {
    margin-top: 2rem;
    border-top: 1px solid #eee;
    padding-top: 1.5rem;
    
    h5 {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    
    .resultsSummary {
      p {
        margin-bottom: 0.5rem;
      }
    }
    
    .resultsTable {
      margin-top: 1.5rem;
      
      h6 {
        font-size: 1rem;
        margin-bottom: 0.75rem;
      }
      
      table {
        width: 100%;
        border-collapse: collapse;
        
        th, td {
          padding: 0.75rem;
          border: 1px solid #eee;
          text-align: left;
        }
        
        th {
          background-color: #f8f9fa;
          font-weight: 600;
        }
        
        .positive {
          color: green;
        }
        
        .negative {
          color: red;
        }
      }
    }
  }
}