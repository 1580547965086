.toastContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 12%;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 99999;

  .toastCol {
    min-width: 380px;
    width: auto;
    .alertDiv {
      padding: 8px 20px !important;
      border-radius: 2px;
      font-weight: 600;
      letter-spacing: 0.5px;
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
      justify-content: center;
      box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12);
      h2{
        font-size: 14px;
        font-weight: 700;
      }
    }
    .alert-success {
      // --bs-alert-color: var(--whiteColor);
      --bs-alert-bg: #e5f6e8;
      --bs-alert-border-color:transparent;
      font-size: 12px;
      border-left: #0f5132 solid 5px;
    }
    .alert-danger {
      // --bs-alert-color: var(--redColor);
      --bs-alert-bg:#f7d2d2;
      --bs-alert-border-color:transparent;
      font-size: 12px;
      border-left: #842029 solid 5px;
    }
  }
}
