.globalPage {
  display: flex;
  flex-direction: column;
  font-family: var(--siteFontFamily);
  font-size: var(--primaryFont);
  min-height: 100vh;
  flex-direction: row;
  .contentPage {
    display: flex;
    position: relative;
    // width: 7%;
    z-index: 10;
    .burgerMenuContainer {
      opacity: 1;
      display: flex;
      padding: 12px;
      padding-left: 10px !important;
      align-items: center;
      background-color: var(--mainBgColor);
      flex-direction: column;
      gap: 45px;
      svg {
        max-height: 20px;
        max-width: 25px !important;
      }
    }

    .contentPageRow2 {
      flex-basis: calc(100% - 215px);
      position: relative;
      overflow: hidden;
      margin-left: auto;
      max-width: calc(100% - 215px);
      display: flex;
      flex-wrap: wrap;
      background-color: var(--whiteColor);
    }

    .sideNavParent {
      min-width: 215px;
      width: 215px;
      flex-basis: 215px;

      .contentPageRow1 {
        position: fixed;
        z-index: 1;
        top: 0;
        bottom: 0;
        min-width: 215px;
        max-width: 215px;
      }
    }

    .whiteBg {
      background-color: var(--whiteColor);
    }
  }
  .contentNoLogin {
    display: flex;
    background-color: var(--mainBgColor);
    width: 5%;
    min-height: 100vh;
    z-index: 20;
    .burgerMenuContainer {
      opacity: 1;
      display: flex;
      padding: 19px 20px;
      padding-left: 10px !important;
      align-items: center;
      position: fixed;
      background-color: var(--mainBgColor);
      flex-direction: column;
      gap: 20px;
      height: 100vh;
      width: 5%;
      svg {
        max-height: 20px;
        width: 30px;
        // max-width: 25px !important;
      }
    }

    .contentPadding {
      padding: 0;
    }
  }
  .headerContainer {
    flex-direction: column;
    flex: 1 1 90%;
    overflow-x: auto;
    .contentPageRow2 {
      padding: 10px 20px 0px 20px;
      width: 100%;
      display: flex;
      background-color: var(--whiteColor);
      height: 90vh;
      overflow: auto;
      .contentPadding {
        width: 100% !important;
      }
    }
  }
  .headerwithFixedsidebar {
    flex-direction: column;
    width: 100%;
    .contentPageRow2 {
      // padding: 10px 20px 0px 20px;
      width: 100%;
      display: flex;
      background-color: var(--whiteColor);
      overflow: auto;
      height: 100%;
      .contentPadding {
      }
    }
  }
}
.onboardingScreen{
  width: 100%;
}
select {
  cursor: pointer;

  option {
    cursor: pointer;
  }
}
.onboardingcontentPageRow2 {
  flex-basis: calc(100% - 215px);
  position: relative;
  overflow: hidden;
  margin-left: auto;
  max-width: calc(100% - 215px);
  display: flex;
  flex-wrap: wrap;
  background-color: var(--whiteColor);
}

@media screen and (max-width: 1050px) {
  .globalPage {
    .headerwithFixedsidebar {
      width: 100%;
    }
  }
  .onboardingcontentPageRow2{
    padding: 0;

  }
  .contentPage {
    .contentPageRow2 {
      margin-left: 0;
      max-width: 100%;
      width: 100%;
      flex-basis: 100%;
      padding: 10px;
      padding-left: 0 !important;
    }

    .sideNavParent {
      min-width: auto;
      width: auto;
      flex-basis: auto;

      .contentPageRow1 {
        left: -100%;
        transition: left 0.5s ease-in;
        padding: 0;

        .burgerMenuContainer {
          opacity: 1;
          transition: all 0.5s ease;
        }
      }

      .contentPageRow1.open {
        left: 0;
        z-index: 2;

        .burgerMenuContainer {
          //opacity: 0;
          svg {
            path {
              // fill: transparent !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 880px) {
  .globalPage {
    .headerContainer {
      flex-direction: column;
      flex: 1 1 90%;
      overflow-x: auto !important;
      .contentPageRow2 {
        // flex-grow: 1;
        // padding: 20px;
        padding: 10px 20px 0px 20px;
        width: 100%;
        display: flex;
        background-color: var(--whiteColor);
        //  justify-content: center;
        // align-items: center;
        height: 80vh;
        overflow: auto;
        .contentPadding {
          width: 100% !important;
        }
      }
    }
    .contentNoLogin {
      width: 10%;
      .burgerMenuContainer {
        width: 10%; //added here to avoide the shrinking of the logo in the sidebar
      }
    }
    .headerwithFixedsidebar {
      width: 100%;
    }
  }
 
}
@media screen and (max-width: 550px) {
  .globalPage{
    .headerwithFixedsidebar{
      max-width: 100%;
      width: 100%;
    }
  }
  .contentPage {
    .contentPageRow2 {
      margin-left: 0;
      max-width: 100%;
    }

    // .contentPageRow1 {
    //   display: none;
    // }
  }
}
